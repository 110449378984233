import { createRoot } from 'react-dom/client'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Toaster } from 'react-hot-toast'

import { BrowserRouter } from 'react-router-dom'
import * as Sentry from "@sentry/react";
import {getSentryConfig} from "./sentryConfig"

const sentryConfig = getSentryConfig();

try {
  if (sentryConfig && sentryConfig.dsn) {
    Sentry.init({
      dsn: sentryConfig.dsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      environment:sentryConfig.environment,
      enabled: !sentryConfig.isLocalhost,
      // Performance Monitoring
      tracesSampleRate: sentryConfig.tracesSampleRate, 
      // Session Replay
      replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate, 
      replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate, 
    })
  }
} catch (error) {
  console.error('Error initializing Sentry:', error);
}

const container = document.getElementById('root')
const root = createRoot(container!)

const startUp = () =>
  root.render(
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            zIndex: 1000
          }
        }}
      />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </>
  )

startUp()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
