import { useContext } from 'react'
import { Receiver } from 'common/types/message.types'
import MessagingContext from 'contexts/messaging/MessagingProvider'
import { useAuth } from 'pages/auth/hooks/useAuth'
import { useMessageApi } from 'hooks/use-message-api/useMessageApi'

export const useReceiver = () => {
  const { backendUser } = useAuth()
  const { setReceiver, receiver } = useContext(MessagingContext)
  const { fetchMessageReceiver } = useMessageApi()

  const getReceiver = async (receiverId: string, shouldSetReceiver = true) => {
    const receiver: Receiver = await fetchMessageReceiver(backendUser.id, receiverId)
    shouldSetReceiver && setReceiver(receiver)
    return receiver
  }

  return {
    getReceiver,
    another: true,
    receiver
  } as const
}
