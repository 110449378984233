import { MIXPANEL_PROPERTIES } from 'common/constants/mixpanel'
import { BackendUser, BackendPartner } from 'common/types'
import { logEventAnalytics } from 'hooks/use-analytics/useAnalytics'
import { getItem, setItem } from 'hooks/use-local-storage/useLocalStorage'

export const useBottomNudge = (backendUser?: BackendUser, backendPartner?: BackendPartner) => {

  const getLocalStorageItem = (name: string) => {
    return getItem(name)
  }

  const storeSessionStart = () => {
    setItem('sessionStart', new Date().toISOString())
  }

  const useAppNudgeTracker = (buttonEvent: string) => {
    const partnerLog = {}
    const userLog = {}

    if (backendUser?.partnerId) {
      partnerLog[MIXPANEL_PROPERTIES?.PARTNER_ID] = backendUser?.partnerId
      partnerLog[MIXPANEL_PROPERTIES?.COMPANY_NAME] = backendPartner?.name
    }

    logEventAnalytics(
      backendUser?.partnerId ? 'Partner' : backendUser?.role,
      buttonEvent,
      'mixpanel',
      buttonEvent,
      {
        [MIXPANEL_PROPERTIES?.USER_ID]: backendUser?.id,
        [MIXPANEL_PROPERTIES?.EMAIL_ADDRESS]: backendUser?.username,
        [MIXPANEL_PROPERTIES?.ACCOUNT_TYPE]: backendUser?.partnerId ? 'Partner' : backendUser?.role,
        ...userLog,
        ...partnerLog
      },
      backendUser?.id,
      {
        $name: `${backendUser?.firstName} ${backendUser?.lastName}`,
        $email: backendUser?.username
      }
    )
  }

  return { getLocalStorageItem, useAppNudgeTracker, storeSessionStart } as const
}
