import { FunctionComponent } from 'react'
import { Box, Typography, Avatar } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Message } from 'common/types/message.types'
import { useChatsListItem } from './hooks/useChatsListItem'
import { Loader } from 'components/core/loader'
import { useTheme } from 'styled-components'
import { formatMessage } from 'common/utils/formatMessage'
import { NewMessageIcon, StyledTypographyBox } from './styled'
import { ConnectedStatusIcon } from 'components/page/direct-messaging/components/ConnectedStatusIcon'
import { checkSocketUserIsConnected } from 'hooks/use-online-status/useOnlineStatus'

interface ChatsDrawerListItemProps {
  message: Message
  setOpenChat: Function
  setReceiver: Function
  openDrawer?: boolean
}

export const ChatsDrawerListItem: FunctionComponent<ChatsDrawerListItemProps> = ({
  message,
  setOpenChat,
  setReceiver,
  openDrawer
}) => {
  const theme = useTheme()
  const { isLoading, openChatPopup, localReceiverInfo, isBackendUserSender } = useChatsListItem(
    message,
    setOpenChat,
    setReceiver
  )
  const messengerName =
    isBackendUserSender && message?.receiverFirstName && message?.receiverLastName
      ? `${message?.receiverFirstName} ${message?.receiverLastName}`
      : `${message?.senderFirstName} ${message?.senderLastName}`
  const adminCompanyNamePrepend = 'Admin - '
  const userCharacterLimitLength = 20
  const partnerCharacterLimitLength = 20

  return (
    <>
      {isLoading && <Loader />}

      {!isLoading && (
        <Box
          width="100%"
          display="flex"
          borderBottom={`1px solid ${theme.colors.supplementary1}`}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            openChatPopup(
              {
                id: isBackendUserSender ? message?.receiverId : message?.senderId,
                image: message.senderImage,
                name: `${message.senderFirstName} ${message.senderLastName}`
              },
              message.id
            )
          }
          padding="0.7em 0 0.75em"
        >
          <Box padding={openDrawer ? '0 0.75em 0.8em' : '0'} display={'flex'}>
            <Box height="min-content" marginRight="0.5em" display="flex" position="relative">
              <Avatar
                src={isBackendUserSender && message?.receiverFirstName ? message?.receiverImage : message?.senderImage}
                style={{ border: `1px solid ${theme.colors.accent1}` }}
              />
              <ConnectedStatusIcon
                connected={checkSocketUserIsConnected(isBackendUserSender ? message?.receiverId : message?.senderId)}
              />
            </Box>
            <Box width="100%">
              <Box width="100%" color={theme.colors.accent1}>
                <Typography component="span" variant="body2">
                  <StyledTypographyBox display="inline">
                    {`${messengerName.slice(0, userCharacterLimitLength)}${
                      messengerName.length > userCharacterLimitLength ? '...' : ''
                    }`}
                  </StyledTypographyBox>
                </Typography>{' '}
                {!localReceiverInfo?.isConnection &&
                  localReceiverInfo?.hasMessagePermission &&
                  localReceiverInfo?.companyName && (
                    <Typography component="span" variant="body2">
                      <StyledTypographyBox fontSize="0.81rem">
                        {adminCompanyNamePrepend}
                        <Box
                          style={{
                            display: 'inline'
                          }}
                        >
                          {localReceiverInfo?.companyName &&
                            `${localReceiverInfo?.companyName.slice(0, partnerCharacterLimitLength)}${
                              localReceiverInfo?.companyName.length > partnerCharacterLimitLength ? '...' : ''
                            }`}
                        </Box>
                      </StyledTypographyBox>
                    </Typography>
                  )}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="caption" component="span">
                    <StyledTypographyBox color={theme.colors.accent}>
                      {isBackendUserSender ? 'You: ' : ''}
                      {formatMessage(message?.content?.message)}
                    </StyledTypographyBox>
                  </Typography>
                  {message?.status === 'unread' && !isBackendUserSender && <NewMessageIcon />}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
