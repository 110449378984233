import {Response} from './response.types'
export interface Message {
    id: string
    senderId?: string
    receiverId?: string
    status?: string //to be changed to the types
    content: Content
    senderImage?: string
    senderFirstName?: string
    senderLastName?: string
    createdAt: string
    edited?: boolean
    receiverImage?: string
    receiverFirstName?: string
    receiverLastName?: string
}

export interface Content {
  message: string
}
export interface Messages extends Response {
  data: Message[]
}

export type Receiver = {
  id: string
  name: string
  image?: string
  isConnection?: boolean
  hasMessagePermission?: boolean
  companyName?: string
  companyId?: string
  restrictedStatus?: string |  null
}

export const INPUT_PLACEHOLDER = 'Write your message...';

export type ChatMessageCreatedPayload = {
  message: Message
  unreadMessagesCount: number
}