export enum PathName {
  FEED = '/feed',
  CONNECTIONS = '/connections',
  PRIVATE_JOBS = '/jobs',
  CREATE_POST = '/createPost',
  MESSAGES = '/messages',
  EVENTS = '/events',
  PINGS = '/pings',
  COMMUNITIES = '/communities',
}
