export const HST_NUMBER = '731912671RT0001'
export const TAX_RATIO = 0
export const DEV_ONLY = process.env.REACT_APP_SHOW_DEV_FEATURES === 'true'
export const MAX_HEIGHT_MEDIA_CALC_MOBILE = 'calc(100vw * 1080 / 1920)'
export const MAX_HEIGHT_MEDIA_CALC_DESKTOP = 'calc((100vw * 0.65 - 3em) * 1080 / 1920)'
export const wordPressUrl = process.env.REACT_APP_WORDPRESS_BASEURL || 'https://obsidi.com/bfutr'
export const awsURL = process.env.REACT_APP_AWS_BUCKET_NAME as string
export const PHONE_NUMBER_PLACEHOLDER = '6473333333'
export const defaultImage = `https://${awsURL}.s3.amazonaws.com/media/images/defaultImage.png`
export const defaultCompanyLogo = `https://${awsURL}.s3.amazonaws.com/media/images/default_company_logo.png`
export const uploadResumeInterfaceWeb = `https://${awsURL}.s3.amazonaws.com/media/images/UploadResume_interface_Web_+1.png`
export const uploadResumeInterfaceMobile = `https://${awsURL}.s3.amazonaws.com/media/images/UploadResume_interface_1.png`
export const resumePreviewInterfaceWeb = `https://${awsURL}.s3.amazonaws.com/media/images/Experience_New_Mockup_Web+1.png`
export const resumePreviewInterfaceMobile = `https://${awsURL}.s3.amazonaws.com/media/images/Experience_uploaded_Mobile+1.png`
export const welcomeModalMobileVideoUrl = `https://${awsURL}.s3.us-east-1.amazonaws.com/media/images/onboardingVideo_desktop.mp4`
export const welcomeModalDesktopVideoUrl = `https://${awsURL}.s3.us-east-1.amazonaws.com/media/images/onboardingVideo_mobile.mp4`
export const suggestedConnectionsCardImageUrl = `https://${awsURL}.s3.us-east-1.amazonaws.com/media/images/suggConnPinnedPost.png`
export const newFeatureBadgeImageUrl = `https://${awsURL}.s3.us-east-1.amazonaws.com/media/images/newIcon.gif`
export const defaultCommunityImage = `https://bptn-frontend-music-dev.s3.us-east-1.amazonaws.com/media/images/communityIcon_web.png`