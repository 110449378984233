import { FunctionComponent, PropsWithChildren, ReactNode, forwardRef } from 'react'
import { Dialog, Slide, Box, Typography } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import styled, { useTheme } from 'styled-components'

const StyledDialog = styled(Dialog)`
  && {
    overflow-y: hidden;
    min-height: '-webkit-fill-available';
    .MuiDialog-paper {
      border-radius: 16px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
  &.MuiDialog-paper {
    overflow-y: hidden;
  }
`

const TopBox = styled(Box)<{ noBorder: string }>`
  border-bottom: ${props => (props.noBorder === 'true' ? 'none' : '1px solid #b4bdca !important')};
`
const GreyCloseIcon = styled(ClearIcon)`
  color: grey;
`

const Transition = forwardRef(function Transition(props, ref) {
  //@ts-ignore
  return <Slide direction="up" ref={ref} {...props} />
})

interface FullScreenModalProps {
  title: string
  open: boolean
  onClose: Function
  backgroundColor?: string
  noBorder?: boolean
}

export const FullScreenModal: FunctionComponent<PropsWithChildren<FullScreenModalProps>> = ({
  title,
  open,
  onClose,
  children,
  backgroundColor,
  noBorder
}) => {
  const theme = useTheme()
  return (
    //@ts-ignore
    <StyledDialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <Box height="100%" bgcolor={backgroundColor || theme.colors.supplementary}>
        <TopBox
          display="flex"
          paddingBottom="20px"
          // @ts-ignore
          onClick={onClose}
          noBorder={noBorder ? 'true' : 'false'}
        >
          <Box display="flex" marginLeft="auto" width="100%" justifyContent="center">
            <Typography component="span" variant="h3">
              <Box color={theme.colors.accent1} fontWeight="bold">
                {title}
              </Box>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row-reverse" flexGrow={1}>
            <GreyCloseIcon />
          </Box>
        </TopBox>
        <Box display="flex" justifyContent="center" flexDirection="column" height="100%">
          {children}
        </Box>
      </Box>
    </StyledDialog>
  )
}
