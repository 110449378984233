import { ChatsDrawer } from 'components/page/chat-drawer/ChatsDrawer'
import { DirectMessagingCard } from 'components/page/direct-messaging'
import { StyledMediaPlayer } from 'components/page/media-player/StyledMediaPlayer'
import ContextProvider from 'contexts/ContextProvider'
import { MessagingProvider } from 'contexts/messaging/MessagingProvider'
import { AdminSideNavProvider } from 'contexts/sideNav/AdminSideNavProvider'
import { ThemeProvider as ThemeSwitch } from 'contexts/theme'
import { WebsocketProvider } from 'contexts/websocket/WebsocketProvider'
import { BPTNThemeManager } from 'hooks/theme'
import { useEffect } from 'react'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components/macro'
import { GlobalStyle, theme } from 'styles/globalstyles'
import { Router, preloadRoutes } from './Router'

//Comment left intentioanlly until secrets is moved to aws
// const GTM_ID = process.env.REACT_APP_GTM_ID || 'GTM-TJZSNH6'

const DEV_ONLY = process.env.REACT_APP_SHOW_DEV_FEATURES === 'true'
const GTM_ID = DEV_ONLY ? 'GTM-TJZSNH6' : 'GTM-KT65L3L'

const tagManagerArgs = {
  gtmId: GTM_ID
}

TagManager.initialize(tagManagerArgs)

const App = ({ history }) => {
  useEffect(() => {
    preloadRoutes()
  }, [])

  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  })

  return (
    <ThemeSwitch>
      <ThemeProvider theme={theme}>
        <BPTNThemeManager selectedLanguage="en">
          <GlobalStyle />
          <ContextProvider>
            <AdminSideNavProvider>
              <StyledMediaPlayer />
              {/* Replaced chat provider with Direct Messaging Provider */}
              <MessagingProvider>
                <WebsocketProvider>
                  <Router />
                  <DirectMessagingCard />
                  <ChatsDrawer />
                </WebsocketProvider>
              </MessagingProvider>
            </AdminSideNavProvider>
          </ContextProvider>
        </BPTNThemeManager>
      </ThemeProvider>
    </ThemeSwitch>
  )
}

export default withRouter(App)
