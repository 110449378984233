import { Message, Messages } from 'common/types/message.types'
import { useState, useLayoutEffect, useContext, useMemo, useEffect } from 'react'
import { useMessageApi } from 'hooks/use-message-api/useMessageApi'
import { useAuth } from 'pages/auth/hooks/useAuth'
import { useLocalStorage } from 'hooks/use-local-storage/useLocalStorage'
import MessagingContext from 'contexts/messaging/MessagingProvider'
import moment from 'moment'
import { useAnalytics } from 'hooks/use-analytics/useAnalytics'
import { usePartnerApi } from 'hooks/use-partner-api/usePartnerApi'
import { useUserApi } from 'hooks/use-user-api/useUserApi'
import { JobProps } from 'common/types'
import { MIXPANEL_PROPERTIES } from 'common/constants/mixpanel'

export const useDirectMessaging = () => {
  const {
    fetchConversations,
    archiveConversation,
    deleteMessage,
    createMessage,
    updateStatus,
    updateMessage
  } = useMessageApi()

  const { isOpen, setOpen, receiver, setReceiver, onlineUsers, newReceivedMessage, setNewSentMessage } = useContext(MessagingContext)

  const { backendUser: sender, isAuthenticated, setUsageSummary } = useAuth()
  const { getItem } = useLocalStorage()
  const [activeChat, setActiveChat] = useState([])
  const [isRead, setIsRead] = useState(false)
  const [editedMessage, setEditedMessage] = useState('')
  const [editMessageId, setEditMessageId] = useState<string>()
  const [deleteMessageId, setDeleteMessageId] = useState<string>()
  const [typedMessage, setTypedMessage] = useState('')
  const [showEdit, setShowEdit] = useState(false)
  const [disablePopup, setDisablePopup] = useState(false)
  const { logEventAnalytics, handleTracker } = useAnalytics()
  const { backendUser, backendPartner } = useAuth()
  const { fetchUsageSummary } = usePartnerApi()
  const { fetchUser } = useUserApi()
  const [receiverMaxDMCount, setReceiverMaxDMCount] = useState<boolean>(false)

  const getConversations = useMemo(
    () => async (userId: string, receiveId: string) => {
      const conversationList: Messages = await fetchConversations(userId, receiveId)
      setActiveChat(conversationList?.data.reverse())
      const receiverUser = await fetchUser(receiveId)
      if (receiverUser.partnerId) {
        const receiverUsageSummary = await fetchUsageSummary(receiverUser.partnerId)
        if (receiverUsageSummary)
          setReceiverMaxDMCount(receiverUsageSummary?.currentDMCount >= receiverUsageSummary?.totalDM)
      }
    },
    []
  )

  const deleteConversation = async (userId: string, receiverId: string) => {
    await archiveConversation(userId, receiverId)
    getConversations(userId, receiver?.id)
    const partnerLog = {}
    const userLog = {}

    userLog[MIXPANEL_PROPERTIES?.USER_ID] = backendUser?.id
    userLog[MIXPANEL_PROPERTIES?.EMAIL_ADDRESS] = backendUser?.username

    if (backendUser?.partnerId) {
      partnerLog[MIXPANEL_PROPERTIES?.PARTNER_ID] = backendPartner?.id
      partnerLog[MIXPANEL_PROPERTIES?.COMPANY_NAME] = backendPartner?.name
      partnerLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = 'Partner'
    } else {
      userLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = backendUser?.role
    }
    logEventAnalytics(
      backendUser?.partnerId ? 'Partner' : 'Member',
      'Delete Conversation',
      'mixpanel',
      'Delete Conversation',
      { ...partnerLog, ...userLog },
      backendUser?.id
    )
  }

  const updateMessageStatus = async (messageId?: string) => {
    const updatedMessage = await updateStatus(messageId)
    return updatedMessage
  }

  const editMessage = async (messageId: string, message: string) => {
    const edit = await updateMessage(messageId, message)
    if (edit) {
      const partnerLog = {}
      const userLog = {}
      userLog[MIXPANEL_PROPERTIES?.USER_ID] = backendUser?.id
      if (backendUser?.partnerId) {
        partnerLog[MIXPANEL_PROPERTIES?.PARTNER_ID] = backendPartner?.id
        partnerLog[MIXPANEL_PROPERTIES?.COMPANY_NAME] = backendPartner?.name
        partnerLog[MIXPANEL_PROPERTIES?.SENDEE_ROLE] = 'Partner'
      } else {
        userLog[MIXPANEL_PROPERTIES?.SENDEE_ROLE] = sender?.role
      }
      logEventAnalytics(
        backendUser?.partnerId ? 'Partner' : backendUser?.role,
        'Message edited',
        'mixpanel',
        'edit message sent',
        {
          [MIXPANEL_PROPERTIES?.SENDEE_ID]: sender?.id,
          [MIXPANEL_PROPERTIES?.SENDEE_EMAIL]: sender?.username,
          ...partnerLog,
          ...userLog
        },
        sender?.id
      )
    }
  }

  const handleSubmit = async (newText?: string) => {
    if (!(newText ?? typedMessage)) {
      return
    }

    var thirdMessage = activeChat[activeChat?.length - 3]
    if (thirdMessage) {
      const time = moment(new Date()).diff(moment(thirdMessage?.createdAt)) / 1000
      if (time < 3) {
        setDisablePopup(true)
        setTimeout(() => {
          setDisablePopup(false)
        }, 5000)
      }
    }
    setTypedMessage('')
    const newMessage: Message = await createMessage(
      sender?.id,
      receiver?.id,
      newText ?? typedMessage
    )
    const newMessageFormatted: Message = {
      id: newMessage.id,
      senderId: sender.id,
      receiverId: newMessage.receiverId,
      status: newMessage.status,
      content: {
        message: newMessage?.content?.message
      },
      createdAt: newMessage.createdAt,
      senderImage: sender.image,
      senderFirstName: sender.firstName,
      senderLastName: sender.lastName
    }
    setActiveChat(prev => [...prev, newMessageFormatted])
    if (newMessage) {
      setNewSentMessage(newMessage)
      const userLog = {}
      const userReceiver = await fetchUser(receiver?.id)
      const job = JSON.parse(getItem('job')) as JobProps | null
      const logData = {
        [MIXPANEL_PROPERTIES?.MESSAGE_RECIPIENT_ID]: receiver?.id,
        [MIXPANEL_PROPERTIES?.MESSAGE_RECIPIENT_EMAIL]: userReceiver?.username,
        [MIXPANEL_PROPERTIES?.RECIPIENT_ROLE]: userReceiver?.partnerId ? 'Partner' : userReceiver?.role,
        [MIXPANEL_PROPERTIES?.SENDEE_ID]: sender?.id,
        [MIXPANEL_PROPERTIES?.PARTNER_ID]: backendPartner?.id,
        [MIXPANEL_PROPERTIES?.COMPANY_NAME]: backendPartner?.name,
        [MIXPANEL_PROPERTIES?.SENDEE_EMAIL]: sender?.username,
        [MIXPANEL_PROPERTIES?.SENDEE_ROLE]: backendPartner?.id ? 'Partner' : sender?.role,
        [MIXPANEL_PROPERTIES?.MESSAGE_SENT]: Boolean(job),
        [MIXPANEL_PROPERTIES?.JOB_ID]: job?.id,
        [MIXPANEL_PROPERTIES?.JOB_NAME]: job?.title
      }
      userLog[MIXPANEL_PROPERTIES?.USER_ID] = backendUser?.id
      logEventAnalytics(
        backendUser?.partnerId ? 'Partner' : backendUser?.role,
        'DM sent',
        'mixpanel',
        'Message successfully sent',
        {
          ...logData,
          ...userLog
        },
        sender?.id
      )
    }
    if (sender?.partnerId) setUsageSummary(sender?.partnerId)
  }

  const formatDate = key => {
    const date = moment(key)
    const isToday = moment().date() === date.date()
    const isThisYear = new Date().getFullYear() === date.year()

    if (isToday) return 'Today'

    if (isThisYear) return moment(date).format('D MMM')

    return moment(date).format('D MMM YYYY')
  }

  const getTimeCreated = (messageDate: string) => {
    const time = moment(new Date()).diff(moment(messageDate)) / 1000 / 60
    return time
  }

  const handleDelete = messageId => {
    deleteMessage(sender.id, messageId)
    setActiveChat(prev => prev.filter(p => p.id !== messageId))
    setDeleteMessageId(undefined)
    const partnerLog = {}
    const userLog = {}
    if (sender?.partnerId) {
      partnerLog[MIXPANEL_PROPERTIES?.PARTNER_ID] = backendPartner?.id
      partnerLog[MIXPANEL_PROPERTIES?.COMPANY_NAME] = backendPartner?.name
      partnerLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = 'Partner'
    } else {
      userLog[MIXPANEL_PROPERTIES?.ACCOUNT_TYPE] = sender?.role
    }
    logEventAnalytics(
      backendUser?.partnerId ? 'Partner' : backendUser?.role,
      'Message deleted',
      'mixpanel',
      'Message deleted',
      { [MIXPANEL_PROPERTIES?.SENDEE_EMAIL]: sender?.username,[MIXPANEL_PROPERTIES?.SENDEE_ID]: sender?.id, ...partnerLog, ...userLog },
      sender?.id
    )
  }

  useEffect(() => {
    setTypedMessage('')
    {
      e => handleChange(e)
    }
  }, [receiver?.id])

  const handleChange = (e) => {
    if (e.target.innerHTML.length <= 500) {
      setTypedMessage(e.target.innerHTML)
    } else {
      setTypedMessage(e.target.innerHTML.substring(0, 500))
    }
  }

  const handleEdit = (messageId, text?: string) => {
    const index = activeChat?.findIndex(msg => msg.id === messageId)
    const arrayWithUpdatedMessage = [...activeChat]

    const message = text ?? editedMessage;
    
    arrayWithUpdatedMessage[index]!.content!.message = `${message}`
    arrayWithUpdatedMessage[index]!.edited = true

    setActiveChat(arrayWithUpdatedMessage)
    editMessage(messageId, message)
    setShowEdit(false);
    setEditMessageId(undefined)
  }

  useLayoutEffect(() => {
    if (receiver?.id || isOpen) {
      getConversations(sender?.id, receiver?.id)
    }
  }, [sender?.id, receiver?.id, isOpen])

  useEffect(() => {
    if (newReceivedMessage) {
      if (activeChat && activeChat.length > 0) {
        if (activeChat[0].senderId == newReceivedMessage?.senderId || activeChat[0].receiverId == newReceivedMessage?.senderId) {
            setActiveChat(prev => [...prev, newReceivedMessage])
            return
        } 
      }
    }
  }, [newReceivedMessage])

  useLayoutEffect(() => {
    const messageInStorage = getItem('messages')
    let messageReceived
    let lastCreatedAt = ''
    let sentBy = ''
    if (messageInStorage) {
      messageReceived = JSON.parse(messageInStorage)
      lastCreatedAt = messageReceived.createdAt
      sentBy = messageReceived.senderId
    }

    if (editMessageId) {
      setEditedMessage(decodeURIComponent(activeChat?.find(msg => msg.id === editMessageId).content.message))
    }
  }, [editMessageId, newReceivedMessage])

  return {
    createMessage,
    deleteConversation,
    updateMessageStatus,
    activeChat,
    setActiveChat,
    getConversations,
    editMessage,
    setIsRead,
    isRead,
    isOpen,
    receiver,
    setOpen,
    editedMessage,
    setEditedMessage,
    editMessageId,
    setEditMessageId,
    sender,
    setReceiver,
    formatDate,
    getTimeCreated,
    handleSubmit,
    handleChange,
    handleEdit,
    handleDelete,
    showEdit,
    setShowEdit,
    disablePopup,
    setDisablePopup,
    deleteMessageId,
    setDeleteMessageId,
    typedMessage,
    isAuthenticated,
    receiverMaxDMCount,
    onlineUsers
  } as const
}
