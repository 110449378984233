import { NotificationType, UserNotificationPreference, UserPreference } from 'common/types'
import { UserInteractions } from 'common/types/interactions.types'
import { get, post, put } from 'hooks/use-http/useHttp'
import { Preferences } from 'pages/auth/hooks/useUserPreference'
import { Dispatch, SetStateAction } from 'react'

export const fetchPreferenceByUser = async (userId: string) => {
  const response: UserPreference = await get(`preference/${userId}`, { dieSilent: true })
  return response
}

export const fetchPreferenceByUserWithApiKey = async (userId: string) => {
  const response: UserPreference = await get(`preference/${userId}`, { useApiKey: true, dieSilent: true })
  return response
}

export const createPreference = async (preference: UserPreference) => {
  const response: UserPreference = await post('preference', preference, { dieSilent: true })
  return response
}
export const createUserNotifications = async (preference: UserNotificationPreference) => {
  const response: UserPreference = await post('preference/notification', preference, { dieSilent: true })
  return response
}

export const updatePreference = async (preference: UserPreference, setLoading?: Dispatch<SetStateAction<boolean>>) => {
  setLoading && setLoading(true)
  const response: UserPreference = await put(`preference/${preference?.userId}/${preference?.id}`, preference, {
    dieSilent: true
  })
  setLoading && setLoading(false)
  return response
}

export const fetchNotificationTypes = async () => {
  const response: NotificationType[] = await get(`/notification-types`, { dieSilent: true })
  return response
}
export const fetchNotificationTypesWithApiKey = async () => {
  const response: NotificationType[] = await get(`/notification-types`, { useApiKey: true, dieSilent: true })
  return response
}

export const fetchAllUserNotificationPreferenceById = async (userId: string) => {
  const response: UserNotificationPreference[] = await get(`/preference/notifications/${userId}`, { dieSilent: true })
  return response
}
export const fetchAllUserNotificationPreferenceByIdWithApiKey = async (userId: string) => {
  const response: UserNotificationPreference[] = await get(`/preference/notifications/${userId}`, {
    useApiKey: true,
    dieSilent: true
  })
  return response
}

export const createBulkNotificationPreferencesWithApiKey = async (payload: Preferences[]) => {
  const response: UserNotificationPreference[] = await post(`preference/notification/bulk`, payload, {
    useApiKey: true,
    dieSilent: true
  })
  return response
}

export const unsubscribeFromAllEmailsWithApiKey = async (username: string) => {
  const response = await put(`preference/unsubscribe/all/user/${username}`, {}, { useApiKey: true, dieSilent: true })
  return response
}

export const getUnsubscribeReasonQuestionsWithApiKey = async () => {
  const response = await get(`unsubscribe-reason/questions`, { useApiKey: true, dieSilent: true })
  return response
}

export const createUnsubscribeReasonWithApiKey = async (payload: any) => {
  const response = await post(`unsubscribe-reason/responses`, payload, { useApiKey: true, dieSilent: true })
  return response
}

const getUserInteractions = async () => {
  const response = await get<UserInteractions>(`/user-interactions`, { dieSilent: true })
  return response
}

const createUserInteraction = async (interactionName: string) => {
  const response = await post<UserInteractions>(`user-interactions`, { name: interactionName }, { dieSilent: true })
  return response
}

export const usePreferenceApi = () => {
  return {
    fetchPreferenceByUser,
    createPreference,
    updatePreference,
    fetchPreferenceByUserWithApiKey,
    createUserNotifications,
    fetchNotificationTypes,
    fetchAllUserNotificationPreferenceById,
    createBulkNotificationPreferencesWithApiKey,
    unsubscribeFromAllEmailsWithApiKey,
    fetchAllUserNotificationPreferenceByIdWithApiKey,
    fetchNotificationTypesWithApiKey,
    getUnsubscribeReasonQuestionsWithApiKey,
    createUnsubscribeReasonWithApiKey,
    getUserInteractions,
    createUserInteraction
  } as const
}
