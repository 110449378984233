export interface SocketUser {
    id: string
    username: string
    socketId: string
    connected: boolean
    date?: string
    name?: string
    avatar?: string
  }

export enum SocketEvent {
  ChatMessageCreated = "chat message created",
  UsersOnline = "users online",
  UserDisconnected = "user disconnected",
  ChatArchived = "chat archived",
  ChatMessageRead = "chat message read",
  NotificationReceived = "notification received",
  BfutrEventTimeTrigger = "bfutr2023 event",
  SHOW_EVENT_BADGE = "show event badge",
  EVENT_APP_BAR_UPDATE = "event app bar update"
}