import { FunctionComponent, PropsWithChildren } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'
import { useMaterialUiTheme } from './useMaterialUITheme'
import { useStyledComponentsTheme } from './useStyledComponentsTheme'
import { useThemeSwitch } from '.'

/* 
  Material-UI supports themes
  Styled-components supports themes

  We feed our Material-Ui theme into styled components theme so our components can use it
*/

interface BPTNThemeManagerProps {
  selectedLanguage: string
  shouldUseWhiteLabel?: Boolean
}

export const BPTNThemeManager: FunctionComponent<PropsWithChildren<BPTNThemeManagerProps>> = ({
  children,
  selectedLanguage,
  shouldUseWhiteLabel
}) => {
  const { mode } = useThemeSwitch()
  const { theme: materialUiTheme } = useMaterialUiTheme(mode, selectedLanguage, shouldUseWhiteLabel)
  const { getTheme } = useStyledComponentsTheme(shouldUseWhiteLabel)
  const styledComponentsTheme = getTheme(mode)

  return (
    <MuiThemeProvider theme={materialUiTheme}>
      <ThemeProvider theme={{ ...styledComponentsTheme }}>{children}</ThemeProvider>
    </MuiThemeProvider>
  )
}
