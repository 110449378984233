import { whiteLabelTheme } from './whiteLabelTheme'

export const themeDark = {
  colors : {
    primary: '#8237B9',
    secondary: '#1DC5D7',
    bgPrimary: '#131313',
    supplementary: '#1A1A1A',
    supplementary1: '#242424',
    supplementary2: '#262626',
    accent: '#AAAAAA',
    accent1: '#EEEEEE',
    success: '#3DD820',
    warning: '#FBF10E',
    error: '#DF2828',
    white: '#FFFFFF',
    shadowSmall: '0px 4px 6px -4px rgba(255, 255, 255, 0.03), 0px 10px 50px -3px rgba(255, 255, 255, 0.10)',
    shadowMedium: '0px 4px 6px -4px rgba(255, 255, 255, 0.03), 0px 10px 50px -3px rgba(255, 255, 255, 0.30)',
    shadowLarge: '0px 4px 6px -4px rgba(255, 255, 255, 0.03), 0px 10px 50px -2px rgba(255, 255, 255, 0.50)',
    primaryAlpha: 'rgba(178, 143, 219, 0.20)',
    qrCodeLogoFill: '#111112',
    lighterPrimary: '#7E45C4',
    likeColor: "#F44444",
    darkGreen: "#98D2B5",
    successGreen: "#43996E",
    warning2: '#E58A28',
    bgLight: '#606060',
    disabled: '#525252',
    borderGray: "#535353"
  },

  fonts: {
    primary: 'montserrat',
    secondary: 'serif'
  },
  vars: {},
  breakpoints: {
    small: '360px',
    medium: '720px',
    large: '1080px',
    xlarge: '1440px'
  }
}

export const themeLight = {
  colors: {
    primary: '#8237B9',
    secondary: '#1DC5D7',
    bgPrimary: '#EEEEEE',
    supplementary: '#FFFFFF',
    supplementary1: '#BBBBBB',
    supplementary2: '#EBE9E9',
    accent: '#333333',
    accent1: '#131313',
    success: '#3DD820',
    warning: '#FBF10E',
    error: '#DF2828',
    white: '#FFFFFF',
    shadowSmall: '0px 4px 6px -4px rgba(0, 0, 0, 0.03), 0px 10px 50px -3px rgba(0, 0, 0, 0.30)',
    shadowMedium: '0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 50px -3px rgba(0, 0, 0, 0.50)',
    shadowLarge: '0px 4px 6px -4px rgba(0, 0, 0, 0.20), 0px 10px 50px -3px rgba(0, 0, 0, 0.70)',
    primaryAlpha: 'rgba(178, 143, 219, 0.20)',
    qrCodeLogoFill: '#111112',
    lighterPrimary: '#7E45C4',
    likeColor: "#F44444",
    darkGreen: "#98D2B5",
    successGreen: "#43996E",
    warning2: '#E58A28',
    bgLight: '#BBBBBB',
    disabled: '#DCDCDC',
    borderGray: "#535353"
  },
  fonts: {
    primary: 'montserrat',
    secondary: 'serif'
  },
  vars: {},
  breakpoints: {
    small: '360px',
    medium: '720px',
    large: '1080px',
    xlarge: '1440px'
  }
}

export type ThemeType = typeof themeDark

export const useStyledComponentsTheme = (shouldUseWhiteLabel: boolean) => {
  const getTheme = (mode: string) => {
    const themeInUse = mode === 'dark' ? themeLight : themeDark
    if (shouldUseWhiteLabel) {
      // We intercept our theme and we modify it to make it 'White Label'
      themeInUse.colors.primary = whiteLabelTheme.primaryMain
      themeInUse.colors.accent = whiteLabelTheme.primaryLight
    }

    return themeInUse
  }

  return { getTheme } as const
}