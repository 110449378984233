import { FunctionComponent, ReactNode } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  DialogContentText,
  Box,
  Typography
} from '@material-ui/core'
import styled from 'styled-components/macro'
//@ts-ignore
import { useTheme } from 'styled-components'
import { useScreenSizes } from 'hooks/use-screen-sizes/useScreenSizes'
import { Button } from 'common/atoms/Button/Button'
import { StyledConfirmationButton, StyledDialog } from './styled'

interface ConfirmationDialogProps extends DialogProps {
  title?: string
  message?: string | ReactNode
  open: boolean
  onClose: () => void
  onConfirm: Function
  children?: ReactNode
  backgroundColor?: string
  color?: string
  cancelBtnLabel?: string
  okBtnLabel?: string
  fullWidth?: boolean
  dialogWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  isOkButtonDisabled?: boolean
  contentPaddingY?: number
  flexDirection?: string
  isDanger?: boolean
  width?: any
  buttonGap?: number 
  CloseButtonDesign?: "primary" | "secondary" | "tertiary"
  showDialogButton?: boolean
}

export const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({
  open,
  title,
  message,
  onClose,
  onConfirm,
  backgroundColor,
  color,
  children,
  cancelBtnLabel,
  okBtnLabel,
  fullWidth,
  dialogWidth,
  isOkButtonDisabled,
  contentPaddingY,
  flexDirection,
  isDanger,
  width,
  buttonGap,
  CloseButtonDesign,
  showDialogButton = true,
  ...props
}) => {
  const theme = useTheme()
  const { isDevice } = useScreenSizes()
  return (
    //@ts-ignore
    <StyledDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      {...props}
      PaperProps={{ style: { backgroundColor: backgroundColor || theme.colors.supplementary } }}
    >
      <Box padding={isDevice ? '0 1em' : '0em 1em 1em 1em'} fontWeight="900">
        <DialogTitle>
          <Typography variant="h6">
            <Box fontWeight="bold" color={color || theme.colors.accent1} textAlign="center">
              {title}
            </Box>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: color || theme.colors.accent1 }}>{message}</DialogContentText>
        </DialogContent>
        {children && <DialogContent>{children}</DialogContent>}
        {showDialogButton && <DialogActions>
          <Box
            display="flex"
            flexDirection={flexDirection ? flexDirection : ''}
            justifyContent={isDevice ? 'center' : 'space-between'}
            width="100%"
            paddingY={contentPaddingY}
            gridGap={buttonGap}
          >
            <Box width={width ? width : "50%"} padding="0 1.5em">
              {/*
          //@ts-ignore*/}
              <Button
                design={CloseButtonDesign ? CloseButtonDesign : 'primary'}
                onClick={onClose} 
                fullWidth
              >
                {cancelBtnLabel ?? 'No'}
              </Button>
            </Box>
            <Box width={width ? width : "50%"} padding="0 1.5em">
              {/*
          //@ts-ignore*/}
              <StyledConfirmationButton
                isDanger = {isDanger}
                design='primary' 
                //@ts-ignore
                onClick={onConfirm} 
                fullWidth 
                disabled={isOkButtonDisabled}
              >
                {okBtnLabel ?? 'Yes'}
              </StyledConfirmationButton>
            </Box>
          </Box>
        </DialogActions>}
      </Box>
    </StyledDialog>
  )
}
