import { UserInteractions } from "common/types/interactions.types";
import { makeAutoObservable } from "mobx";

class InteractionsStore {

    interactions: UserInteractions = [];

    constructor() {
        makeAutoObservable(this);
    }

    setInteractions = (interactions: UserInteractions) => {
        this.interactions = interactions;
    }

}

export const interactionsStore = new InteractionsStore();