import { PathName } from 'common/types/pathName.types'
import { FC, PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

interface IChatMessageIcon {
  count: number
  pathname: string
}

export const ChatMessageIcon: FC<PropsWithChildren<IChatMessageIcon>> = ({ count, pathname }) => {
  const theme = useTheme()
  return (
    <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9339 3.125H12.8947C17.7678 3.125 21.7182 7.32233 21.7182 12.5C21.7182 17.6777 17.7678 21.875 12.8947 21.875H6.03192C3.8661 21.875 2.11035 20.0095 2.11035 17.7083V12.5C2.11035 7.32233 6.06078 3.125 10.9339 3.125ZM7.9927 15.3646C7.58661 15.3646 7.25741 15.0148 7.25741 14.5833C7.25741 14.1519 7.58661 13.8021 7.9927 13.8021H11.9143C12.3204 13.8021 12.6496 14.1519 12.6496 14.5833C12.6496 15.0148 12.3204 15.3646 11.9143 15.3646H7.9927ZM7.9927 11.1979C7.58661 11.1979 7.25741 10.8481 7.25741 10.4167C7.25741 9.98519 7.58661 9.63542 7.9927 9.63542H15.8358C16.2419 9.63542 16.5711 9.98519 16.5711 10.4167C16.5711 10.8481 16.2419 11.1979 15.8358 11.1979H7.9927Z"
        fill={pathname === PathName.MESSAGES ? theme.colors.primary : theme.colors.accent}
      />

      {count > 0 && (
        <>
          <circle cx="22.0498" cy="7" r="6.5" fill="#DF2828" stroke="#1A1A1A" />
          <text
            x="22.0498"
            y="7"
            textAnchor="middle"
            dominantBaseline="middle"
            fontWeight={600}
            fontSize="0.375rem"
            fill="#EEEEEE"
          >
            {count}
          </text>
        </>
      )}
    </svg>
  )
}
