import { forwardRef, FunctionComponent, PropsWithChildren, ReactNode } from 'react'

import { Backdrop, Box, Dialog, Divider, Slide, Typography } from '@material-ui/core'
import {
  StyledBottomLeftQuarterCircle,
  StyledBottomRightQuarterCircle,
  StyledTopLeftQuarterCircle,
  StyledTopRightQuarterCircle
} from '../../../pages/summit/bfutr2023/styled'
import { CircledGreyCloseIcon, DialogInner, GreyCloseIcon, TopBox } from './styled'
//@ts-ignore
import { useTheme } from 'styled-components'
import styled from 'styled-components/macro'

const StyledDialog = styled(Dialog)<{ radius?: string; overflow?: string }>`
  && {
    overflow-y: hidden;
    .MuiDialog-paper {
      border-radius: ${props => (props?.radius !== '' ? props.radius : '15px')};
      overflow-y: scroll;
      ${props => (props?.overflow ? `overflow: ${props.overflow};` : '')}
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      box-shadow: none;
    }
    .MuiDialog-paperFullScreen { 
      height: 100vh;
    }
  }
  &.MuiDialog-paper {
    overflow-y: hidden;
  }
`

export const StyledDivider = styled(Divider)`
  && {
    background-color: ${props => props.theme.colors.supplementary1};
    height: 0.063rem;
    margin: 0;
  }
`

const Transition = forwardRef(function Transition(props, ref) {
  //@ts-ignore
  return <Slide direction="up" ref={ref} {...props} />
})

interface ModalProps {
  onClose: Function
  show: boolean
  title: ReactNode
  titleVariant?: any
  showCancel?: boolean
  noBorder?: boolean
  noPadding?: boolean
  noMargin?: boolean
  titleColor?: string
  titleSize?: string
  dialogWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  margin?: string
  backgroundColor?: string
  centerTitle?: boolean
  borderColor?: string
  radius?: string
  fullWidth?: boolean
  overflow?: string
  circledCancel?: boolean
  cancelSize?: string
  borderRadius?: string
  isSummit?: boolean
  showDivider?: boolean | false
  fullScreen?: boolean
  noSpacing?: boolean
  headerPadding?: string
}
export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({
  show,
  title,
  titleVariant,
  showCancel,
  children,
  onClose,
  noBorder,
  noPadding,
  noMargin,
  dialogWidth,
  titleColor,
  titleSize,
  margin,
  backgroundColor,
  centerTitle,
  borderColor,
  fullWidth,
  radius,
  overflow,
  circledCancel,
  cancelSize,
  borderRadius,
  isSummit,
  showDivider,
  fullScreen,
  noSpacing,
  headerPadding
}) => {
  const theme = useTheme()

  const _margin = () => {
    if (noSpacing) {
      return '0'
    }
    return noMargin ? '1em 1em 0 0' : ''
  }

  return (
    <StyledDialog
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      open={show}
      //@ts-ignore
      onClose={() => onClose()}
      BackdropProps={{
        timeout: 500
      }}
      keepMounted
      BackdropComponent={Backdrop}
      //@ts-ignore
      TransitionComponent={Transition}
      maxWidth={dialogWidth}
      radius={radius ? radius : 'unset'}
      overflow={overflow}
    >
      <Box
        bgcolor={backgroundColor || theme.colors.supplementary}
        color={theme.colors.primaryLight}
        borderRadius={borderRadius ? borderRadius : 'unset'}
      >
        {isSummit && (
          <>
            <StyledTopLeftQuarterCircle />
            <StyledTopRightQuarterCircle />
            <StyledBottomLeftQuarterCircle />
            <StyledBottomRightQuarterCircle />
          </>
        )}
        <TopBox
          display="flex"
          margin={noMargin ? '0' : margin || '0.5em 0'}
          paddingBottom={noPadding ? '0px' : '10px'}
          noborder={noBorder ? 'true' : 'false'}
          borderColor={borderColor}
        >
          <Box display={'flex'} width={'100%'} margin={margin || noMargin ? '0' : '0 0.5em'} padding={headerPadding}>
            <Box display="flex" marginLeft="auto" width="100%" justifyContent={centerTitle ? 'center' : 'unset'}>
              <Typography component="span" variant={titleVariant ? titleVariant : 'h5'}>
                <Box color={titleColor || theme.colors.accent1} fontSize={titleSize} fontWeight="bold">
                  {title}
                </Box>
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row-reverse" flexGrow={1}>
              {showCancel && (
                <>
                  {circledCancel ? (
                    <Box
                      //@ts-ignore
                      onClick={onClose}
                    >
                      <CircledGreyCloseIcon
                        style={{
                          margin: _margin(),
                          height: cancelSize ? cancelSize : '',
                          width: cancelSize ? cancelSize : ''
                        }}
                      />
                    </Box>
                  ) : (
                    <>
                      <GreyCloseIcon
                        //@ts-ignore
                        onClick={onClose}
                        style={{
                          //@ts-ignore
                          margin: _margin(),
                          cursor: 'pointer',
                          height: cancelSize ? cancelSize : '',
                          width: cancelSize ? cancelSize : ''
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        </TopBox>
        {showDivider && <StyledDivider />}
        <DialogInner noMargin={noMargin} backgroundColor={backgroundColor}>
          {children}
        </DialogInner>
      </Box>
    </StyledDialog>
  )
}
