import { AppBar, BackendPaginatedResponse, Content, PinnedPost } from 'common/types'
import { calcTotalPages } from 'common/utils/calcTotalPages'
import { makeAutoObservable } from 'mobx'
import { Lounge, LoungePageName, SavedEventSession, Session } from 'pages/content/components/events/bftur-agenda-itinerary/types'

export type PostAudience = {
  label: string;
  value: string;
  icon: JSX.Element;
}

class contentsStoreState {
  pinnedPost: PinnedPost
  sessionTab: number
  savedLounges: BackendPaginatedResponse<Lounge>
  savedSessions: SavedEventSession[]
  searchedSessions: BackendPaginatedResponse<Session>
  lounges: BackendPaginatedResponse<Lounge>
  selectedLounge: Lounge = null
  loungeSessions: BackendPaginatedResponse<Session>
  loungesPage: number = 1
  showCheckInModal: boolean = false
  session: Session
  isLoungeListDisabled: boolean = false
  loungesTotalCount: number = 0
  loungesPageSize: number = 5
  appBarActionLocation: string
  appBar: AppBar
  showGetAppModal: boolean = false
  qrcCode: string
  renderFullPage: boolean = false
  currentPageName: LoungePageName | ''
  searchedSessionKeyword: string = ''
  searchedPage = 1
  posts: Content[] = []
  isCommunityFeed = false
  communityId = ''
  postAudience: PostAudience 

  constructor() {
    makeAutoObservable(this)
  }

  setPosts = (posts: Content[]) => {
    this.posts = posts
  }

  setPinnedPost = (pinnedPost: PinnedPost) => {
    this.pinnedPost = pinnedPost
  }

  setLounges = (lounges?: BackendPaginatedResponse<Lounge>) => {
    this.lounges = lounges
  }
  setSelectedLounge = (selectedLounge: Lounge) => {
    this.selectedLounge = selectedLounge
  }

  setSessionTabs = (sessionTabs: number) => {
    this.sessionTab = sessionTabs
  }

  setSavedLounges = (savedLounges: BackendPaginatedResponse<Lounge>) => {
    this.savedLounges = savedLounges
  }

  setSavedSessions = (savedSessions: SavedEventSession[]) => {
    this.savedSessions = savedSessions
  }

  setLoungeSessions = (loungeSessions: BackendPaginatedResponse<Session>) => {
    this.loungeSessions = loungeSessions
  }
  setLoungesPage = (loungesPage: number) => {
    this.loungesPage = loungesPage
  }

  setShowCheckInModal = (showCheckInModal: boolean) => {
    this.showCheckInModal = showCheckInModal
  }

  setSession = (session: Session) => {
    this.session = session
  }

  setLoungeListDisabled = (isDisabled: boolean) => {
    this.isLoungeListDisabled = isDisabled
  }

  setLoungesTotalCount = (loungesTotalCount: number) => {
    this.loungesTotalCount = loungesTotalCount
  }

  setLoungesPageSize = (loungesPageSize: number) => {
    this.loungesPageSize = loungesPageSize
  }

  setAppBarActionLocation = (appBarActionLocation: string) => {
    this.appBarActionLocation = appBarActionLocation
  }

  setAppBar = (appBar: AppBar) => {
    this.appBar = appBar
  }

  setShowGetAppModal = (showGetAppModal: boolean) => {
    this.showGetAppModal = showGetAppModal
  }

  setQrCode = (qrcCode: string) => {
    this.qrcCode = qrcCode
  }

  setRenderFullPage = (renderFullPage: boolean, pageName: LoungePageName | '') => {
    this.renderFullPage = renderFullPage
    this.currentPageName = pageName
  }

  setSearchedSessions = (searchedSessions: BackendPaginatedResponse<Session>) => {
    this.searchedSessions = searchedSessions
  }

  setSearchedSessionKeyword = (searchedSessionKeyword: string) => {
    this.searchedSessionKeyword = searchedSessionKeyword
  }

  setSearchedPage = (searchedPage: number) => {
    this.searchedPage = searchedPage
  }

  setCommunityFeed = (isCommunityFeed: boolean) => {
    this.isCommunityFeed = isCommunityFeed
  }

  setCommunityId = (communityId: string) => {
    this.communityId = communityId
  }

  setPostAudience = (postAudience: PostAudience) => {
    this.postAudience = postAudience
  }

  get loungesList() {
    return this.lounges
  }

  get totalPages() {
    return calcTotalPages(this.loungesTotalCount, this.loungesPageSize)
  }
}

export const contentsState = new contentsStoreState()
