import { OnboardingQuestion } from './question.types'

export interface Connection {
  id?: string
  userId: string
  connectId: string
  status: string
  firstName: string
  lastName: string
  image?: string
  jobTitle: string
  createdAt?: string
  updatedAt?: string
}

export interface Incoming {
  page: number
  pageSize: number
  dir: string
  sort: string
  totalCount: number
  data: IncomingConnection[]
}

export interface IncomingConnection {
  senderId: string
  senderFirstName: string
  senderLastName: string
  senderImage: string
  senderOnboardingQuestions: OnboardingQuestion[]
  connectionId: string
  connectId: string
  status: string
  firstName: string
  lastName: string
  image: string
  updatedAt: string
}

export interface ConnectionCount {
  connectedConnectionCounts: string | number
  pendingConnectionCounts: string | number
  blockedConnectionCounts: string | number
  receivedConnectionCounts: string | number
}

export interface UserSuggestedConnections {
  page: string
  pageSize: string
  dir: string
  totalCount: string
  data: UserSuggestedConnection[]
}

export interface UserSuggestedConnection {
  userId: string
  name: string
  areaOfExpertise?: string
  companyName?: string
  avatar?: string
  compositeScore?: number
  areaOfExpertiseSimilarity?: number
  industrySimilarity?: number
  jobTitleSimilarity?: number
  locationSimilarity: number
  location?: string
  metaData?: string
}

export enum SuggestedConnectionsMessage {
  TOOLTIP_MESSAGE = 'Help us suggest better matches for you! Grow your community by connecting with relevant suggestions and closing matches that don’t align with your preferences.'
}

export interface DismissedSuggestedConnection {
  id?: string
  userId: string
  suggestedConnectionId: string
  compositeScore: number
  metaData?: any
}

export interface SuggestedConnectionFeedbackPayload {
  dismissedSuggestedConnectionId: string
  feedbackOptionIds: string[]
}

export interface DismissedSuggestedConnectionPayload {
  suggestedConnectionId: string
  compositeScore: number
  metaData?: any
}

export interface SuggestedConnectionFeedbackOption {
  id: string
  option: string
}

export enum MyTribeEmptyStateMessage {
  REQUESTS = 'You currently have no Requests. Grow your network by exploring your suggested connections.',
  CONNECTIONS = 'You currently do not have any connection.  Take action - explore suggested connections and grow your network now!',
  PENDING = 'Expand your Black tech talent network. Connect with professionals and allies who align with your interests and career goals—start sending requests today.',
  BLOCKED = 'You currently have no blocked user',
  COMPANIES = "You're not following anyone yet. Discover and follow companies to stay updated on the latest jobs and news",
  SUGGESTED_CONNECTIONS = 'Complete your profile to get better suggested connections. Our algorithm uses your details to match you with the right people helping you grow your network faster!'
}
