import axios, { AxiosError } from 'axios'
import { getApiKey, getAuthorizationToken, getUserFromToken, session_key, storeToken } from 'hooks/use-security/useSecurity'
import { notify } from '../use-notification/useNotification'
import { clearAll, getItem, setItem } from 'hooks/use-local-storage/useLocalStorage'
import { sleep } from 'common/utils/sleep'
import { Session } from 'common/types'

const { REACT_APP_API_URL } = process.env

const authErrors = [
  'Invalid refresh token',
  'Missing refresh token',
  'Session not found',
  'JWT verification failed: jwt expired',
  'Invalid access token'
]


const getClient = async (options?: any, version?: string) => {
  const headers: HeadersInit = options?.notJSON
    ? {}
    : {
      'Content-Type': 'application/json'
    }

  headers['x-api-key'] = options.useApiKey ? getApiKey() : ''
  const client = axios.create({
    baseURL: version ? REACT_APP_API_URL?.replace('v1', version) : REACT_APP_API_URL,
    timeout: options?.timeout || 30000,
    headers: headers,
    withCredentials: true
  })

 client.interceptors.request.use(
   async config => {
    const headers = config.headers
    const token = getItem(session_key)
    if (!headers.common?.['x-api-key']) {
      headers.Authorization = `Bearer ${token}`
    }
    return config
   },
   error => Promise.reject(error)
 )

 client.interceptors.response.use(
   response => response,
   async error => {
    const err = error as AxiosError
    if (err.response?.status === 403) {
      const response = await client.put<Session>(`${REACT_APP_API_URL}/sessions/user/refresh`)
      const session = response.data
      if (session) {
        await setItem(session_key, session.accessToken)
        err.config.headers.Authorization = `Bearer ${session.accessToken}`
      }
      return client(err.config)
    }

    if (err.response?.status === 401) {
      const errorMessage = err.response?.data?.httpMessage
      if (authErrors.includes(errorMessage)) {
        clearAll()
        window.location.assign(`${process.env.REACT_APP_REDIRECT_URI}`)
      }
    }

    return Promise.reject(error)
   }
 )

  return client
}

const setDefaults = (options: any) => {
  if (!options) {
    options = {}
  }

  if (!options.hasOwnProperty('errorMessage')) {
    // default to generic api error
    options.errorMessage = 'A server error has occurred.  Please try again.'
  }

  return options
}

const handleHttpError = (errorObject, data, options: any) => {
  if (
    errorObject.response?.status === 400 ||
    errorObject.response?.status === 401 ||
    errorObject.response?.status === 404
  ) {
    //toDo
    //navigate('/login')
    let errorMessage = errorObject.response?.data?.httpMessage
    try {
      const message = JSON.parse(errorMessage)
      if (message.message) errorMessage = message.message
    } catch (e) { }

    if (errorMessage?.includes('Message=Presentation not currently available.')) {
      return notify('error', 'Event is not live yet')
    }

    !options?.dieSilent && notify('error', errorMessage)
    return options?.dieSilent ? '' : errorObject?.response?.data?.httpMessage
  }
  if (options?.dieSilent) return
  switch (errorObject?.response?.data?.httpMessage) {
    case 'PayloadTooLargeError':
      notify('error', 'Your Post was too large. Maximum acceptable size is 25mb')
      break
    default:
      notify('error', errorObject?.response?.data?.httpMessage || options.errorMessage)
      break
  }
  return options?.dieSilent ? '' : errorObject?.response?.data?.httpMessage || options.errorMessage
}

export const get = async <T = any>(url, _options?: any, version?: string): Promise<T> => {
  const options = setDefaults(_options)
  try {
    
    const client = await getClient(options, version)
    const response = await client.get(url)
    const data = await response.data
    return data
  } catch (e) {
    const data = {
      source: 'useHttp.get',
      url: `${REACT_APP_API_URL}/${url}`
    }
      handleHttpError(e, data, options)

    return Promise.reject(e)
  }
}

export const put = async <T = any>(url, requestData, _options?: any, version?: string): Promise<T> => {
  const options = setDefaults(_options)
  try {
    const client = await getClient(options, version)
    const response = await client.put(url, requestData)
    const data = await response.data
    return data
  } catch (e) {
    const data = {
      source: 'useHttp.put',
      url: `${REACT_APP_API_URL}/${url}`,
      requestData
    }
    if (options.handleError) {
      return { error: true, message: e?.response?.data?.httpMessage ?? options.errorMessage } as any
    } else {
      const backendError = handleHttpError(e, data, options)
      return Promise.reject(backendError)
    }
  }
}

export const post = async <T = any>(url, requestData, _options?: any, version?: string): Promise<T> => {
  const options = setDefaults(_options)
  const baseURL = version ? REACT_APP_API_URL?.replace('v1', version) : REACT_APP_API_URL

  try {
    const client = await getClient(options)
    const response = await client.post(`${baseURL}/${url}`, requestData)
    const data = await response.data
    return data
  } catch (e) {
    const data = {
      source: 'useHttp.post',
      url: `${REACT_APP_API_URL}/${url}`,
      requestData
    }
    handleHttpError(e, data, options)

    return Promise.reject(e)
  }
}

export const destroy = async <T = any>(url, _options?: any, requestData?: any, version?: string): Promise<T> => {
  const options = setDefaults(_options)
  const baseURL = version ? REACT_APP_API_URL?.replace('v1', version) : REACT_APP_API_URL

  try {
    const client = await getClient(options)
    const response = await client.delete(`${baseURL}/${url}`)
    const data = await response.data
    return data
  } catch (e) {
    const data = {
      source: 'useHttp.delete',
      url: `${REACT_APP_API_URL}/${url}`
    }
    handleHttpError(e, data, options)

    return Promise.reject(e)
  }
}

export const useHttp = () => {
  return {
    get,
    put,
    post,
    destroy
  } as const
}