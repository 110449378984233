import {
  BackendPartner,
  SubscribedPartner,
  FollowedPartnerList,
  PartnerFollower,
  PartnerFollowerList,
  UserGroup,
  EmployeeRole,
  UserFunction,
  UserAdmin,
  JobStatus,
  AdminsPartnerList,
  UsageSummary,
  PartnerMetrics,
  BackendSubscription
} from 'common/types'
import { get, post, put, destroy } from 'hooks/use-http/useHttp'
import { useAuth } from 'pages/auth/hooks/useAuth'

export const usePartnerApi = () => {
  const { backendUser } = useAuth()

  const fetchPartner = async (id: string) => {
    const response: BackendPartner = await get(`partner-profile/${id}`, { dieSilent: true })
    return response
  }
  const fetchPartnerWithApiKey = async (id: string) => {
    const response: BackendPartner = await get(`partner-profile/${id}`, {useApiKey: true, dieSilent: true })
    return response
  }

  const fetchPartnerSubscription = async (partnerId: string) => {
    const response: BackendSubscription = await get(`partner-subscription/subscription/${partnerId}`, { dieSilent: true})
    return response
  }

  const updatePartnerProfile = async (id: string, payload: BackendPartner) => {
    const response: BackendPartner = await put(`partner-profile/${id}`, payload, { dieSilent: true })
    return response
  }

  const createPartnerFollower = async (partnerProfileId: string, userId: string) => {
    const response: PartnerFollower = await post(`partner-followers/${partnerProfileId}/${userId}`, {})
    return response
  }

  const getPartnerFollowers = async (partnerProfileId: string, page: number = 1) => {
    const response: PartnerFollowerList = await get(
      `partner-followers/${partnerProfileId}?page=${page}&pageSize=6&dir=desc&sort=updated_at`
    )
    return response
  }

  const getPartnersByFollowerId = async (userId: string, page: number = 1) => {
    const response: FollowedPartnerList = await get(
      `partner-followers/follower/${userId}?page=${page}&pageSize=20&dir=desc&sort=updated_at`
    )
    return response
  }

  const getPartnerFollowerByUserId = async (userId: string, partnerProfileId: string) => {
    const response: PartnerFollower = await get(`partner-followers/${userId}/${partnerProfileId}`)
    return response
  }

  const removePartnerFollower = async (partnerFollowerId: string) => {
    const response: PartnerFollower = await destroy(`partner-followers/${partnerFollowerId}`)
    return response
  }

  const createPartnerProfileWithApiKey = async (userId: string, payload: BackendPartner) => {
    const response: SubscribedPartner = await post(`partner-profile/${userId}`, payload, { useApiKey: true })
    return response
  }

  const createPaidPartnerProfileWithApiKey = async (
    userId: string,
    subscriptionName: string,
    payload: BackendPartner
  ) => {
    const response: SubscribedPartner = await post(
      `partner-profile/${userId}/subscription/${subscriptionName}`,
      payload,
      { useApiKey: true }
    )

    return response
  }

  const createPartnerByPromoCode = async (userId: string, promoCode: string, payload: BackendPartner) => {
    const response: SubscribedPartner = await post(`partner-profile/${userId}/promo/${promoCode}`, payload, { useApiKey: true })
    return response
  }


  const getPartnerProfileByName = async (name: string) => {
    const response: BackendPartner = await get(`partner-profile/name/${name}`)
    return response?.name
  }

  //change endpoints to partner endpoints
  const fetchPartnerGroupsByPartnerId = async (partnerId: string) => {
    const response: UserGroup[] = await get(`partner-admin/admin-role/partner/${partnerId}`)
    return response
  }

  //get Admin user groups by group id
  const getAdminUserGroupsByGroupId = async (groupId: string) => {
    const response = await get(`partner-admin/admin-role/${groupId}`, { dieSilent: true })
    return response
  }

  const fetchPartnerAdminUsersByPartnerId = async (partnerId: string) => {
    const response: UserAdmin[] = await get(`partner-admin/admin-role/partner/${partnerId}`, { dieSilent: true })
    return response
  }

  const fetchPartnerAdminUsersByUserIdAndPartnerId = async (
    userId: string,
    partnerProfileId: string,
    page: number,
    pageSize: number = 10
  ) => {
    const response: AdminsPartnerList = await get(
      `partner-admin/user-admin-role/${userId}/partner/${partnerProfileId}?page=${page}&pageSize=${pageSize}&dir=desc&sort=created_at`,
      { dieSilent: true }
    )
    return response
  }

  const fetchPartnerRoleByPartnerId = async (partnerId: string) => {
    const response: EmployeeRole[] = await get(`partner-admin/user-admin-role/partner/${partnerId}`, {
      dieSilent: true
    })
    return response
  }

  const createPartnerGroups = async (userId: string, role: UserGroup) => {
    const payload = {
      name: role?.name,
      description: role?.description,
      createdBy: backendUser?.username,
      userId: backendUser?.id,
      partnerId: backendUser?.partnerId
    }
    const response: UserGroup = await post(`partner-admin/admin-role/${userId}`, payload, { dieSilent: true })
    return response
  }

  const deletePartnerGroups = async (userId: string, adminRoleId: string) => {
    return await destroy(`partner-admin/admin-role/${adminRoleId}/user/${userId}`, { dieSilent: true })
  }

  const deletePartnerJob = async (userId: string, jobId: string) => {
    return await destroy(`job/${userId}/${jobId}`, { dieSilent: true })
  }

  const closePartnerJob = async (userId: string, jobId: string) => {
    const payload = {
      status: JobStatus.CLOSED
    }

    const response = await put(`job/${userId}/${jobId}`, payload, { dieSilent: true })
    return response
  }

  const disablePartnerUserGroup = async (userId: string, userGroup: UserGroup, roleId: string) => {
    const payload = {
      disabled: userGroup?.disabled
    }
    const response = await put(`partner-admin/admin-role/${roleId}/user/${userId}`, payload, { dieSilent: true })
    return response
  }

  const fetchPartnerFunctions = async (pageNumber?: number, pageSize?: number) => {
    const response: UserFunction[] = await get(`user-function`, { dieSilent: true })
    return response
  }

  const fetchPartnerFunctionAndRoleByRole = async (roleId: string) => {
    const response = await get(`partner-admin/user-role-function/${roleId}`, { dieSilent: true })
    return response
  }

  const fetchPartnerFunctionsByUser = async (userId: string) => {
    const response: UserFunction[] = await get(`partner-admin/user-function/user/${userId}`)
    return response
  }

  const createPartnerAdminRoles = async (userId: string, roleId: string, functionId: string) => {
    const payload = {
      functionId,
      roleId,
      partnerId: backendUser?.partnerId
    }
    const response = await post(`partner-admin/user-role-function/${userId}`, payload, { dieSilent: true })
    return response
  }

  const createPartnerAdmin = async (roleId: string, userId: string) => {
    const payload = {
      roleId: roleId,
      userId: userId,
      partnerId: backendUser?.partnerId,
      createdBy: backendUser?.username
    }

    const response = await post(`partner-admin/user-admin-role/${backendUser?.id}`, payload, { dieSilent: true })
    return response
  }

  const createBulkPartnerFunction = async (userId: string, adminRoleId: string, permissions: any[]) => {
    const payload = {
      adminRoleId,
      userRoleFunctions: permissions
    }
    const response = await post(`partner-admin/user-role-function/${adminRoleId}/bulk/user/${userId}`, payload, {
      dieSilent: true
    })
    return response
  }

  const fetchPartnerUserAndRolesByRole = async (
    roleId: string,
    page: number = 1,
    withMetaData: boolean = false,
    pageSize: number = 10
  ) => {
    const response = await get(
      `partner-admin/user-admin-role/${roleId}?page=${page}&pageSize=${pageSize}&dir=desc&sort=created_at`,
      {
        dieSilent: true
      }
    )

    return withMetaData ? response : response?.data
  }

  const fetchAdminUserGroupsByRoleId = async (roleId: string) => {
    
    if (roleId) {
      const response = await get(`partner-admin/admin-role/${roleId}`, { dieSilent: true })

      return response
    }
  }

  const fetchPartnerUserAndRolesByUserId = async (userId: string) => {
    const response = await get(`partner-admin/user-admin-role/user/${userId}`, { dieSilent: true })
    return response
  }

  const deleteAssignedPartnersByRole = async (userId: string, roleId: string) => {
    return await destroy(`partner-admin/user-admin-role/${roleId}/user/${userId}`, { dieSilent: true })
  }

  const updatePartnerGroup = async (userId: string, userGroup: UserGroup, roleId: string) => {
    const payload = {
      name: userGroup?.name
    }
    const response = await put(`partner-admin/admin-role/${roleId}/user/${userId}`, payload, { dieSilent: true })
    return response
  }

  const fetchUsageSummary = async (partnerId: string) => {
    const response: UsageSummary = await get(`usage-summary/${partnerId}`, { dieSilent: true })
    return response
  }

  const fetchPartnerMetrics = async (partnerId: string) => {
    const response: PartnerMetrics = await get(`usage-summary/${partnerId}/metrics`, { dieSilent: true })
    return response
  }

  const sendPartnerWelcomeEmail = async (
    partnerId: string,
    userId: string,
    partner: BackendPartner,
    type: 'corporate' | 'community'
  ) => {
    const response = await put(`partner-profile/${partnerId}/user/${userId}/welcome/${type}`, partner, {
      dieSilent: true,
      useApiKey: true
    })
    return response
  }

  return {
    fetchPartner,
    updatePartnerProfile,
    createPartnerFollower,
    createPartnerProfileWithApiKey,
    createPaidPartnerProfileWithApiKey,
    getPartnerFollowers,
    getPartnersByFollowerId,
    getPartnerFollowerByUserId,
    removePartnerFollower,
    getPartnerProfileByName,
    fetchPartnerGroupsByPartnerId,
    fetchPartnerRoleByPartnerId,
    createPartnerGroups,
    deletePartnerGroups,
    fetchPartnerFunctions,
    fetchPartnerFunctionAndRoleByRole,
    fetchPartnerFunctionsByUser,
    createPartnerAdminRoles,
    createPartnerAdmin,
    deleteAssignedPartnersByRole,
    updatePartnerGroup,
    fetchPartnerUserAndRolesByRole,
    createBulkPartnerFunction,
    fetchPartnerUserAndRolesByUserId,
    disablePartnerUserGroup,
    deletePartnerJob,
    closePartnerJob,
    fetchPartnerAdminUsersByPartnerId,
    getAdminUserGroupsByGroupId,
    fetchPartnerAdminUsersByUserIdAndPartnerId,
    fetchAdminUserGroupsByRoleId,
    fetchUsageSummary,
    sendPartnerWelcomeEmail,
    fetchPartnerMetrics,
    fetchPartnerSubscription,
    createPartnerByPromoCode,
    fetchPartnerWithApiKey
  } as const
}
