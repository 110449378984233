import { makeAutoObservable } from 'mobx'

class nudgeDownloadState {
    showNudge = false
    blurred = false
  
  constructor() {
    makeAutoObservable(this)
  }

  setShowNudge = (showNudge: boolean) => {
    this.showNudge = showNudge
  }

  setBlurred = (blurred: boolean) => {
    this.blurred = blurred
  }

}

export const downloadState = new nudgeDownloadState()
