import { Children, FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import Menu from '@material-ui/core/Menu'
import { useCurrentTarget } from 'hooks/use-current-target/useCurrentTarget'
import { MenuItem, IconButton, Box, Badge } from '@material-ui/core'
import styled from 'styled-components/macro'
import { lighten } from 'polished'
import { ActionContextMenuItem, Variant } from 'common/types'


interface CustomIconContextMenuProps {
  title: string
  icon: any
  menuItems?: ActionContextMenuItem[]
  showCounterBadge?: boolean
  counterValue?: number
  menuWidth?: string
  noPadding?: boolean
  anchorOriginHorizontal?: number | 'center' | 'left' | 'right'
  transformOriginVertical?: number | 'center' | 'top' | 'bottom'
  customItemPadding?: string
  customOnClick?: Function
  bgColor?: string
  disabled?: boolean
  disableTouchRipple?: boolean
}

const getVariantColor = (variant: Variant, props: any) => {
  switch (variant) {
    case 'primary': {
      return props.theme.colors.primary
    }
    case 'danger': {
      return props.theme.colors.error
    }
    case 'disabled': {
      return props.theme.colors.accent
    }
  }
}
const MainMenu = styled(({ menuWidth, ...props }) => <Menu {...props} />)<{ menuWidth?: string; bgColor?: string }>`
  && {
    border-radius: 3px !important;
    width: ${props => (props.menuWidth ? props.menuWidth : '24em')} !important;
    height: auto;
  }
  && .MuiMenu-paper {
    background-color: ${props => props.bgColor ?? lighten(0.15, props.theme.colors.supplementary)};
    width: max-content !important;
  }
`
const StyledMenuItem = styled(MenuItem)<{ first?: boolean; last?: boolean; variant: Variant }>`
  color: ${props => getVariantColor(props.variant, props)} !important;
  border-bottom: 1px solid ${props => (!props.last ? lighten(0.25, props.theme.colors.accent) : 'none')} !important;
`

export const CustomIconContextMenu: FunctionComponent<PropsWithChildren<CustomIconContextMenuProps>> = ({
  showCounterBadge,
  counterValue,
  icon,
  menuItems,
  menuWidth,
  anchorOriginHorizontal,
  transformOriginVertical,
  noPadding = false,
  children,
  customItemPadding,
  customOnClick,
  bgColor,
  disabled = false,
  disableTouchRipple = false
}) => {
  const { anchorEl, handleCustomEventClick, handleReset } = useCurrentTarget()
  const getAnchorOriginHorizontal = anchorOriginHorizontal ? anchorOriginHorizontal : 45
  const getTransformOriginVertical = transformOriginVertical ? transformOriginVertical : -45

  return (
    <Box>
      <IconButton
        onClick={e => {
          e.stopPropagation()
          if (customOnClick) {
            customOnClick(e)
            return
          }
          handleCustomEventClick(e)
        }}
        disabled={disabled}
        color="primary"
        style={{
          padding: noPadding ? 0 : undefined
        }}
      >
        {!showCounterBadge && icon}
        {showCounterBadge && (
          <Badge badgeContent={counterValue} variant="standard" color="primary">
            {icon}
          </Badge>
        )}
      </IconButton>
      <MainMenu
        anchorOrigin={{ vertical: 'bottom', horizontal: getAnchorOriginHorizontal }}
        transformOrigin={{ vertical: getTransformOriginVertical, horizontal: 'right' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleReset}
        keepMounted
        menuWidth={menuWidth}
        bgColor={bgColor}
        disableScrollLock
      >
        {Children.map(children, (x, i) => {
          return x === null ? null : (
            <MenuItem
              disableTouchRipple={disableTouchRipple}
              onClick={!disableTouchRipple && handleReset}
              key={i}
              style={{ padding: customItemPadding ? customItemPadding : undefined }}
            >
              {x}
            </MenuItem>
          )
        })}
        {menuItems &&
          menuItems.map(item => {
            return (
              <StyledMenuItem
                onClick={e => {
                  if (item.variant !== 'disabled') {
                    item.action()
                  }
                  handleReset(e)
                }}
                variant={item.variant}
                first={menuItems.indexOf(item) === 0}
                last={menuItems.indexOf(item) === menuItems.length - 1}
                style={{ padding: customItemPadding ? customItemPadding : undefined }}
              >
                {item.label}
              </StyledMenuItem>
            )
          })}
      </MainMenu>
    </Box>
  )
}
