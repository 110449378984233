import { SocketEvent } from 'common/types'
import { useSocket } from 'hooks/use-socket/useSocket'
import { contentsState } from 'models/contents-store/contents.store'
import { createContext, useEffect } from 'react'

const WebsocketContext = createContext(null)

export const WebsocketProvider = ({ children }) => {
  const { socket } = useSocket()

  useEffect(() => {
    // Register all socket.io events here
    socket.on(SocketEvent.EVENT_APP_BAR_UPDATE, data => {
      contentsState.setAppBar(data.appBar)
      contentsState.setPinnedPost(data.pinnedPost)
    })
    return () => {
      socket.off(SocketEvent.EVENT_APP_BAR_UPDATE)
    }
  }, [])

  return <WebsocketContext.Provider value={{ socket }}>{children}</WebsocketContext.Provider>
}
