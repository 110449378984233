import { DEV_ONLY } from 'common/globalConstants'
import mixpanel from 'mixpanel-browser'

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || 'f66ea27c6ea2ebbb72e09a10b58775fa'

mixpanel.init(MIXPANEL_TOKEN, {
    debug: DEV_ONLY,
    record_sessions_percent: 100  //records 100% of all sessions
})

export const logMixpanelEvent = {
    identify: (id) => {
        mixpanel.identify(id)
    },
    alias: (id) => {
        mixpanel.alias(id)
    },
    track: (id, properties?) => {
        mixpanel.track(id, properties)
    },
    people: {
        set: (props) => {
           mixpanel.people.set(props)
        },
    },
}

export const useMixpanel = () => {
    return {
        logMixpanelEvent
    } as const

}
