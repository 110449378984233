import { Messages, Receiver } from 'common/types/message.types'
import { destroy, get, post, put } from 'hooks/use-http/useHttp'

export const fetchMessages = async (userId: string, page: number = 1, pageSize: number = 5) => {
  const response: Messages = await get(
    `message/${userId}/latest?page=${page}&pageSize=${pageSize}&dir=desc&sort=created_at`,
    { dieSilent: true }
  )
  return response
}

export const fetchConversations = async (userId: string, receiverId: string, page: number = 1, pageSize: number = 5) => {
  const response: Messages = await get(
    `message/${userId}/conversation/${receiverId}?page=&pageSize=&dir=desc&sort=created_at`,
    { dieSilent: true, useApiKey: true }
  )
  return response
}

export const fetchMessageReceiver = async (userId: string, receiverId: string) => {
  const response: Receiver = await get(`message/${userId}/receiver/${receiverId}`, { dieSilent: true, useApiKey: true })
  return response
}

export const createMessage = async (userId?: string, receiverId?: string, message?: string) => {
  const payload = { content: { message } }
  return await post(`message/${userId}/${receiverId}`, payload, { dieSilent: true })
}

export const archiveConversation = async (userId?: string, receiverId?: string) => {
  return await put(`message/${userId}/archive/${receiverId}`, { dieSilent: true })
}

export const deleteMessage = async (userId?: string, messageId?: string) => {
  return await destroy(`message/${messageId}/user/${userId}`, { dieSilent: true })
}

export const updateStatus = async (messageId?: string) => {
  const payload = { status: 'read' }
  return await put(`message/${messageId}`, payload, { dieSilent: true })
}

export const updateMessage = async (messageId?: string, message?: string) => {
  const payload = {
    content: { message } ,
    edited: true
  }
  return await put(`message/${messageId}`, payload, { dieSilent: true })
}

export const useMessageApi = () => {
  return {
    fetchMessages,
    fetchConversations,
    createMessage,
    archiveConversation,
    updateStatus,
    deleteMessage,
    updateMessage,
    fetchMessageReceiver
  } as const
}
